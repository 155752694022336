import React, { useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router";
import { getItemFromLocal, getToken } from "../../../utils";
import api from "../../../utils/api";
import {
  clientId,
  currencySign,
  displayRazorpay,
  isSubscribed,
  Loader,
} from "../../../utils/helpers";

import "./subscribePlans.scss";

export default function SubscribePlans({ subscriptionPlans }) {
  const [selectedPlan, setSelectedPlan] = useState({});
  const [loading, setLoading] = useState(false);
  const userInfo = getItemFromLocal("user");
  const history = useHistory();
  const isPlanSelected = () => {
    if (Object.keys(selectedPlan).length > 0) return true;
    else toast.error("Please select any plan");
  };

  const isUserAvailable = () => {
    if (getToken()) return isPlanSelected();
    else history.push("/login");
  };

  const handleSubscribeNow = (e) => {
    if (getToken()) {
      displayRazorpay(e, selectedPlan?.finalPrice, "", handleSubcribePlan);
    } else {
      history.push("/login");
    }
  };

  const handleSubcribePlan = (paymentId) => {
    console.log("paymentId123", paymentId);

    const payload = {
      clientID: clientId(),
      userID: userInfo?.userId,
      planID: null,
      isSubcription: true,
      subcriptionType: selectedPlan.id,
      days: selectedPlan.days,
    };
    if (isUserAvailable()) {
      setLoading(true);
      api
        .post("userSubscription/SubscribeUser", payload)
        .then((res) => {
          if (res?.data?.success) {
            toast.success("Plan subscribed successfully");
            setLoading(false);
            localStorage.setItem("userSubcription", true);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data);
          setLoading(false);
        });
    }
  };
  return (
    <div>
      {Loader(loading)}
      {subscriptionPlans.length > 0 && !isSubscribed() && (
        <div className="subscribe-plans-all-content-alignment">
          <div className="container">
            <div className="plan-text">
              <h4>Subscribe Plans</h4>
              {/* <p>No charge until 7 day trial ends . Cancel anytime.</p> */}
            </div>
            <div className="grid">
              {subscriptionPlans.length > 0 &&
                subscriptionPlans.map((plan) => (
                  <>
                    <div
                      className={
                        selectedPlan?.id == plan?.id
                          ? "active-state grid-items"
                          : "grid-items"
                      }
                      onClick={() => setSelectedPlan(plan)}
                    >
                      {plan?.discount > 0 ? (
                        <h5>
                          <s style={{ color: "#7e818c" }}>
                            {currencySign()}
                            {plan?.price}
                          </s>
                          &nbsp;&nbsp;
                          {currencySign()}
                          {plan.finalPrice}/{plan.planType}
                        </h5>
                      ) : (
                        <h5>
                          {currencySign()}
                          {plan?.price}/{plan.planType}
                        </h5>
                      )}

                      <p>
                        {plan.planType} billed at {plan.finalPrice}$/
                        {plan.planType}
                      </p>
                    </div>
                  </>
                ))}
            </div>
            <div className="button-center-alignment">
              <button onClick={handleSubscribeNow}>Subscribe Now</button>
              {/* <button>Get Trial</button> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
